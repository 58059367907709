<template>
	<div style="padding: 15px;">
		<h1>Terms and Conditions of Use</h1>
		<div class="terms">
			<div>
				By logging into and using this online medical reporting software, you agree to the following terms and conditions:
			</div>
			<div>
				Medical Disclaimer: This platform facilitates communication between specialist doctor and diagnostic centers for reporting purposes. However, we do not provide medical advice, diagnoses, or treatment. Any medical decisions or actions taken based on the reports generated through this platform are the sole responsibility of the healthcare providers and patients involved.
			</div>
			<div>
				Limitation of Liability: The platform owners, developers, and affiliates shall not be held liable for any inaccuracies, delays, or errors in the reporting process, nor for any medical complications, misdiagnoses, or adverse outcomes resulting from the use of this software.
			</div>
			<div>
				User Responsibility: Specialist doctors and diagnostic centers are solely responsible for verifying the accuracy and completeness of the reports. Users must exercise their professional judgment and follow appropriate clinical protocols when interpreting and utilizing the reports.
			</div>
			<div>
				Data Privacy: We prioritize the security and confidentiality of patient data. However, users are responsible for maintaining the privacy and integrity of the information shared through the platform and complying with relevant data protection regulations.
			</div>
			<div>
				Acceptance of Terms: By logging in, users acknowledge that they understand and accept these terms. Continued use of the platform constitutes ongoing acceptance of any future updates or modifications to these terms and conditions.
			</div>
			<div>
				If you do not agree with these terms, please do not proceed with logging into the system.
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Terms',
	data() {
		return {
			// Add your data properties here
		};
	},
	computed: {
		// Add your computed properties here
	},
	methods: {
		// Add your methods here
	},
	mounted() {
		// Add your mounted lifecycle hook here
	}
};
</script>

<style scoped>
.terms div{
	margin-top: 20px;
}
</style>